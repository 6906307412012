import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { t } = useTranslation();
  // const history = useHistory();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activationCode, setActivationCode] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    // navigate('/dashboard', { replace: true });
    const payload = {
      "email" : email,
      "password" : password,
      "activationCode" : activationCode
    }

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    // let responseStatus = 400;
    // fetch(`${process.env.REACT_APP_API_URL}/user/authentication/register`, requestOptions)
    // .then(response => response.json())
    // .then(data => {
    //   navigate('/dashboard', { replace: true });
     
    // })
    // .catch((error) => { 
    //   window.alert("该用户已存在！");
    // })
    fetch(`${process.env.REACT_APP_API_URL}/admin/authentication/register?language=en`, requestOptions)
        .then(response => {
            if(!response.ok) {
                return response.text();
            }
                return response.json();
        })
        .then(data => {
            if (typeof data === 'string') {
                window.alert(data);
            } else {
                window.alert(t('RegisterSuccessfully'));
                navigate('/loginfDFewfDfeDFSFFue738JDuewifDueufsd89rjudef7UEr3457esfd', { replace: true });
            }
        })
        .catch(error => {
            window.alert(error);
        })
    // try {
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}/user/authentication/register`, requestOptions);
    //   // responseStatus = response.status;
    //   // console.log(response);
    //   if(!response.ok) {
    //     throw new Error("User already exists!Please login directly or change your email.");
    //   } else {
    //     // window.location.replace("http://localhost:3000");
    //     window.alert("Registration finished successfully.Now you can log in.");
    //     navigate('/login', { replace: true });
    //   }
      
    // } catch (error) {
    //   window.alert(error.message);
      
    // }
    
  
   
    //   await new Promise(resolve => setTimeout(resolve, 5000));
    //   // code to execute after 5 seconds
    //     if(responseStatus === 201) {
    //   // navigate('/login', { replace: true});
    //   console.log(responseStatus);
    // }
    // console.log(responseStatus);
    
  };

  return (
      <>
        <Stack spacing={3}>
          <TextField name="email" label={t('EmailLoginForm')} onChange={ (e) => setEmail(e.target.value) } />
        

          <TextField
            name="password"
            label={t('PasswordLoginForm')}
            onChange={ (e) => setPassword(e.target.value) }
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField 
          name="activationCode" 
          label={t('Activation Code')}
          onChange={ (e) => setActivationCode(e.target.value)} />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <Checkbox name="remember" label="RememberMe" /> */}
          
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          {t('Register')}
        </LoadingButton>
      </>
  );
}
