import Header from "src/layouts/dashboard/header";
import { useEffect, useState } from 'react';
import './aboutpage.css';

export default function AboutPage() {

    const [index, setIndex] = useState(0);
    const identities = [
        'Software Engineer',
        'World Explorer',
        'Dream Weaver'
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % identities.length);
        }, 1200);

        return () => clearInterval(interval);
    }, [index]);

    return (
        <div>
            <Header hideMenuIcon="true" active={2}/>
            <div style={{
                // border:"solid red 1px",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
            }}>
                <div className="gradient-back-container">
                    <div className="a-home-made">A home-made</div>
                    <div className="identity">{identities[index]}</div>
                </div>
            </div>
        </div>
    );
}