import React, { useRef } from 'react';
import { IKContext, IKImage, IKUpload } from 'imagekitio-react';
import {  Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import IconButton from '@mui/material/IconButton';



const publicKey = "public_xJatryKHLmG/NcmO14UgZ0+XykY=";
const urlEndpoint = "https://ik.imagekit.io/tqz5ngetw";
const authenticationEndpoint = `${process.env.REACT_APP_API_URL}/imagekit/auth`;



function InsertImageInPostComponent({ onImageInsertFinished}) {
  const inputRefTest = useRef(null);
  const ikUploadRefTest = useRef(null);
  const labelRef = useRef(null);
  const videoLabelRef = useRef(null);
  const ikVideoUploadRef = useRef(null);

  const handleUploadClick = () => {
    console.log(inputRefTest);
    inputRefTest.current.click();
  };


  const onError = err => {
    console.log("Error", err);
  };

  const onSuccess = res => {
    console.log("Success", res);
    onImageInsertFinished(res.url);
  };

  const onUploadProgress = progress => {
    console.log("Progress", progress);
    // We use the following two parameters to calcule the percentage of the image uploading
    // onTrackUploadingPercentage(progress.loaded, progress.total);
  };

  const onUploadStart = evt => {
    console.log("Start", evt);
    // onStartUploadImage();
  };

  const authenticate = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/imagekit/auth`) // Replace with your authentication endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Authentication failed");
        }
        return response.json();
      })
      .then((data) => {
        return data; // Assuming your authentication response contains a 'token' field
      });
  };

  const onVideoError = err => {
    // setUploadVideoMessage("视频上传失败！");
    console.log("Error", err);
  };
  
  const onVideoSuccess = res => {
    // setUploadVideoMessage("视频上传成功！");
    console.log("Success", res);
    // onVideoUploadSuccess(res.url);
  };
  
  const onVideoUploadProgress = progress => {
    // setUploadVideoMessage("视频正在上传！");
    console.log("Progress", progress);

  };
  
  const onVideoUploadStart = evt => {
    // setUploadVideoMessage("视频开始上传！");
    console.log("Start", evt);
  };

  return (
    <div>
      {/* <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleFileChange} /> */}
      {/* <button onClick={handleUpload}>Upload Image</button> */}
      <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint} authenticator={authenticate}>
        {/* <IKImage path="/default-image.jpg" transformation={[{ "height": "300", "width": "400" }]} /> */}
        {/* <IKUpload fileName="my-upload.png" onError={onError} onSuccess={onSuccess}/> */}
        {/* <h2>Advanced file upload</h2> */}
        <label style={{display:"none"}} ref={labelRef}>
            <IKUpload
            fileName="profile_image.png"
            tags={["sample-tag1", "sample-tag2"]}
            customCoordinates={"10,10,10,10"}
            isPrivateFile={false}
            useUniqueFileName={true}
            responseFields={["tags"]}
            folder={"/profileimages"}
            validateFile={file => file.size < 200000000}
            webhookUrl="https://ik.imagekit.io/tigerwang" // replace with your webhookUrl
            overwriteFile={true}
            overwriteAITags={true}
            overwriteTags={true}
            overwriteCustomMetadata={true}
            onError={onError}
            onSuccess={onSuccess}
            onUploadProgress={onUploadProgress}
            onUploadStart={onUploadStart}
            //   style={{display: 'none'}} // hide the default input and use the custom upload button
            inputRef={inputRefTest}
            ref={ikUploadRefTest}
            />
        </label>

        {/* <p>Custom Upload Button</p> */}
        {
            labelRef && 
            <Tooltip title="Insert Image" style={{margin:"0px 5px"}} onClick={() => labelRef.current.click()}>
                <IconButton>
                    <InsertPhotoIcon />
                </IconButton>
            </Tooltip>
        }

        {/* {inputRefTest && <button onClick={handleUploadClick}>Upload</button>} */}

        {/* <p>Abort upload request</p>  */}

        {/* {ikUploadRefTest && <Button variant="contained" onClick={() => {
            console.log(ikUploadRefTest);
            ikUploadRefTest.current.abort();
        }}>Abort request</Button>} */}


        {/* <label style={{ display: "none" }} ref={videoLabelRef}>
          <IKUpload
            fileName="video_file.mp4" // Set the video file name
            folder="/videofiles" // Set the destination folder
            validateFile={(file) => file.size < 5000000000} // Adjust the size limit as needed
            onError={onVideoError}
            onSuccess={onVideoSuccess}
            onUploadProgress={onVideoUploadProgress}
            onUploadStart={onVideoUploadStart}
            inputRef={ikVideoUploadRef}
          />
        </label> */}

        {/* {videoLabelRef && (
          <div style={{display:"flex",marginTop:"50px"}}>
            <Button
              variant="contained"
              onClick={() => videoLabelRef.current.click()}
            >
              上传视频
            </Button>
            <div style={{color:"red", margin:"5px 20px"}}>{uploadVideoMessage}</div>
          </div>
        )} */}
      </IKContext>
    </div>
  );
};

export default InsertImageInPostComponent;
