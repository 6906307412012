import PropTypes from 'prop-types';
// @mui
import { MenuItem, TextField } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
};

export default function BlogPostsSort({ options, sortByDate, sortingOrder }) {
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const [activeSortingOrder, setActiveSortingOrder] = useState('New');

  const handleClickAwayFromMenu = () => {
    if(openNavMenu){
      setOpenNavMenu(false);
    }
  };

  const handleToggleOpenMenu = () => {
    if(openNavMenu) {
      setOpenNavMenu(false);
    } else {
      setOpenNavMenu(true);
    }
  };

  // useEffect(() => {}, [openNavMenu]);

  return (
    // sortingOrder has a default value "latest",because in the backend, we sort the data by date in descending order.
    // <TextField select size="small" value={sortingOrder} >
    //   {options.map((option) => (
    //     <MenuItem key={option.value} value={option.value} onClick={() => sortByDate(option.value)}>
    //       {option.label}
    //     </MenuItem>
    //   ))}
    // </TextField>

    <div 
      style={{
        // position:"absolute", 
        // right:"0px", 
        // top: "3px",
        position:"relative",
        fontSize: "16px",
        // border:"solid red 1px",
        color: "#281a2c",
        cursor: "pointer",
        WebkitTapHighlightColor: "transparent"

      }}
      onClick={handleToggleOpenMenu}
      >

      <div style={{
        border: "solid #8b97a2 1px",
        borderRadius: "3px",
        padding: "6px 10px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }}>
        {activeSortingOrder}

        {
          openNavMenu
          ? <div style={{
              marginLeft:"7px",
              width: '0',
              height: '0',
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderBottom: '6px solid #637381',
            }}></div>
          : <div style={{
            marginLeft:"7px",
            width: '0',
            height: '0',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '6px solid #637381',

          }}></div>
        }

      </div>

      {openNavMenu && (
        <ClickAwayListener onClickAway={handleClickAwayFromMenu}>
          {/* New div for additional options */}
          <div style={{
            position:"absolute",
            zIndex: 9999,
            marginTop: "3px", // Space between the two divs
            border: "solid #8b97a2 1px",
            borderRadius: "3px", // Rounded corners for the new div
            backgroundColor: '#f8dab9',
            width: "100%"
          }}>
            {
              options.map((option, index) => (
                <>
                  <div style={{
                    border: index === 0 ? '' : "solid 1px #637381",
                    borderRadius: "5px",
                    // marginBottom: "3px",
                    width: "90%",
                    margin:"3px auto"
                  }}></div>
                  <div 
                    style={{
                      margin:"3px", 
                      display:"flex", 
                      alignItems:"center", 
                      justifyContent:"center",
                      borderRadius:"5px"
                      // borderTop: index === 0 ? '' : 'solid 1px #637381'
                    }}
                    key={index}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e9c7af'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                    // onClick={() => handleNavigation(item.url, index)}
                    onClick={() => {
                      setActiveSortingOrder(option.label);
                      sortByDate(option.value);
                    }}
                  >
                    {option.label}
                  </div>
                </>
              ))
            }
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
