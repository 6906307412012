import { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {

  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation();

  useEffect(() => {
    
    // window.alert(localStorage.getItem("currentLanguage"));
    i18n.changeLanguage(localStorage.getItem("currentLanguage"));
  } , []);

  return (
    <>
      <Helmet>
        <title> {t('login')} | Uniqueman </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

 {/* 2024-05-17: Now we just don't want it anymore, so we add false here */}
        {mdUp && false && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 12, mt: 0, mb: 10 }}>
              {t('HiWelcomeBack')}
            </Typography>
            {/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
            <img
              src="/assets/images/covers/cover1009.png"
              alt="login"
              style={{borderRadius:"20px",margin:"10px 5px"}}
            /><br/>
            <img
              src="/assets/images/covers/cover10011.png"
              alt="login"
              style={{borderRadius:"20px",margin:"10px 5px"}}
            /><br/>
            {/* <img src="/assets/images/covers/cover1005.png" alt="login" /> */}

          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('SignInUniqueman')}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {t('NoAccount')}
              <Link variant="subtitle2" href="/registeraasd3eDF39uEDdEYFHDUfhasudifashff2Sui237hfyf">{t('GetStarted')}</Link>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('OR')}
              </Typography>
            </Divider>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
