import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import EditPage from './pages/EditPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';


import ShowPostDetail from './pages/ShowPostDetail';
// ----------------------------------------------------------------------

export default function Router() {


  const routes = useRoutes([
   
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // If we typy the url like "localhost:3000/dashboard/" without any specific path to it,
        // "/dashboard/blog" will be loaded by default.
        { element: <Navigate to="/dashboard/blog" />, index: true },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'home',
      element: <HomePage />
    },
    {
      path: 'more',
      element: <AboutPage />
    },
    {
      path: 'showPostDetails/:postId',
      element: <ShowPostDetail />
    },
    {
      path: 'editPage/:postId',
      element: <EditPage />
    },
    {
      path: 'loginfDFewfDfeDFSFFue738JDuewifDueufsd89rjudef7UEr3457esfd',
      element: <LoginPage />,
    },
  
    {
      path: 'registeraasd3eDF39uEDdEYFHDUfhasudifashff2Sui237hfyf',
      element: <RegisterPage />
    },
    {
      // If we simply type "localhost:3000" without any further details,
      // "/dashboard/blog" will be loaded by default
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        
      ],
    }

  ]);

  return routes;
}
