
import './Timeline.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState, useRef, useEffect } from 'react';   

const timelineData = [
    {   
        year: '', 
        title: "Roger's Stories", 
        description: "Since 2018, Roger has enjoyed bringing complex ideas from his mind into reality.", 
        date: "",
        url: "/assets/images/timeline/rogerstories.png", 
    },
    { 
        year: 2018, 
        title: `printf("Hello World!");`, 
        description: `Roger just received his first PC and began his journey into the tech world by printing out "Hello World".`, 
        date: "August, 2018",
        url: "/assets/images/timeline/helloworld.png",
    },
    {
        year: 2019, 
        title: "sudo", 
        description: "The world is built on top of Linux, and Roger loved it, so he started using Ubuntu as his daily driver.", 
        date: "May, 2019",
        url: "/assets/images/timeline/sudo.png",
    },
    { 
        year: 2021, 
        title: "LineageOS", 
        description: "Thanks to LineageOS global contributors, Roger saved his OnePlus 5T from becoming outdated.", 
        date: "September, 2021",
        url: "/assets/images/timeline/lineageos2.png"
    },
    {   
        year: 2022, 
        title: "Coding in Clouds", 
        description: "Roger joined a local engineering team to help them solve practical problems using software solutions.", 
        date: "March, 2022",
        url: "/assets/images/timeline/codeinclouds.png", 
    },
    { 
        year: '', 
        title: "Windows 11 on ARM", 
        description: "The tech world is amazing. Roger finally managed to dual-boot Windows 11 and Android on his OnePlus 6T.", 
        date: "June, 2022",
        url: "/assets/images/timeline/win11arm.png",
    },
    { 
        year: '', 
        title: "Safety Helmet Detection System", 
        description: "To address the security issues faced by the team, Roger designed and developed a safety helmet detection system with Spring Boot and React.", 
        date: "December, 2022",
        url: "/assets/images/timeline/helmet.png"
    },
    {   
        year: 2023, 
        title: "https://uniqueman.dev", 
        description: "It's great to work with software engineers accross the globe, to learn from each other and to grow together. So Roger started his personal website to share his ideas and experiences.", 
        date: "April, 2023",
        url: "/assets/images/timeline/uniquemandev.png", 
    },
    { 
        year: '', 
        title: "Graduation", 
        description: "Roger graduated from Hunan University of Technology with a Bachelor degree in Software Engineering.", 
        date: "June, 2023",
        url: "/assets/images/timeline/graduation.png",
    },
    { 
        year: '', 
        title: "Online Training Platform", 
        description: "Roger designed and implemented an online training platform with Spring Boot and React to facilitate the education and assessment of employees.", 
        date: "August, 2023",
        url: "/assets/images/timeline/onlinetraining.png"
    },
    {   
        year: 2024, 
        title: "Farewell", 
        description: "Finally, it's time to say goodbye. Thanks to the team, Roger has gained so much, both professionally and personally. But now, he's embarking on a new journey.", 
        date: "February, 2024",
        url: "/assets/images/timeline/farewell.png", 
    },
    { 
        year: '', 
        title: "LinkedIn Auto-posting App", 
        description: "Roger worked with a global team to develop an app that automatically posts on LinkedIn whenever a commit has been pushed, utilizing ChatGPT to elaborate on the commit message.", 
        date: "June, 2024",
        url: "/assets/images/timeline/linkedinautopost.png",
    },
    { 
        year: '', 
        title: "Redesign", 
        description: "Roger introduced a brand-new design to his personal website that enhanced the user experience, making navigation between pages enjoyable.", 
        date: "August, 2024",
        url: "/assets/images/timeline/redesign.png",
    },
  ];


export default function Timeline() {

    const [currentIndex, setCurrentIndex] = useState(0); // Start from the first one


    const timelineRef = useRef(null);

    // const centerActiveItem = (index) => {
    //     const timelineElement = timelineRef.current;
    //     const activeElement = timelineElement.children[index];

    //     const timelineRect = timelineElement.getBoundingClientRect();
    //     const activeRect = activeElement.getBoundingClientRect();

    //     const offset = activeRect.left - timelineRect.left - (timelineRect.width / 2) + (activeRect.width / 2);
    //     timelineElement.scrollBy({
    //         left: offset,
    //         behavior: 'smooth',
    //     });
    // };

    // const centerActiveItem = (index) => {
    //     const timelineElement = timelineRef.current;
    //     if (timelineElement) {
    //       const activeElement = timelineElement.children[index];
    //       const timelineRect = timelineElement.getBoundingClientRect();
    //       const activeRect = activeElement.getBoundingClientRect();
      
    //       // Calculate the offset to center the active item
    //       let offset = activeRect.left - timelineRect.left - (timelineRect.width / 2) + (activeRect.width / 2);
      
    //       // Check if scrolling would go beyond the scroll range, and adjust
    //       const maxScrollLeft = timelineElement.scrollWidth - timelineElement.clientWidth;
      
    //       if (timelineElement.scrollLeft + offset < 0) {
    //         offset = -timelineElement.scrollLeft; // Prevent scrolling beyond the left limit
    //       } else if (timelineElement.scrollLeft + offset > maxScrollLeft) {
    //         offset = maxScrollLeft - timelineElement.scrollLeft; // Prevent scrolling beyond the right limit
    //       }
      
    //       timelineElement.scrollBy({
    //         left: offset,
    //         behavior: 'smooth',
    //       });
    //     }
    //   };

    const centerActiveItem = (index) => {
        const timelineElement = timelineRef.current;
        if (timelineElement) {
          const activeElement = timelineElement.children[index];
          const timelineRect = timelineElement.getBoundingClientRect();
          const activeRect = activeElement.getBoundingClientRect();
      
          // Calculate the offset to center the active item
          let offset = activeRect.left - timelineRect.left - (timelineRect.width / 2) + (activeRect.width / 2);
      
          // Scroll by the calculated offset
          timelineElement.scrollBy({
            left: offset,
            behavior: 'smooth',
          });
        }
      };
      

    useEffect(() => {
        // Center the active item on initial render
        centerActiveItem(currentIndex);
    }, [currentIndex]);

    const navigate = (direction) => {
        if (direction === 'prev' && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (direction === 'next' && currentIndex < timelineData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const jumpToYear = (index) => {
        setCurrentIndex(index);
        centerActiveItem(index);
    };


    return(
        <div className="timeline-container">
            {/* Top section for displaying information */}
            <div className="top-section-container">
                {/* Navigation button left */}
                <div className="nav-arrow" onClick={() => navigate('prev')}>
                    <KeyboardArrowLeftIcon />
                </div>

                {/* Content disply section */}
                <div className="content-display">
                    <div className="content-icon-img">
                        <img src={timelineData[currentIndex].url} />
                    </div>
                    <p id="content-title">{timelineData[currentIndex].title}</p>
                    <div id="content-desc">{timelineData[currentIndex].description}</div>
                    <div style={{color:"grey"}}>{timelineData[currentIndex].date}</div>
                </div>

                {/* Navigation button right */}
                <div className="nav-arrow" onClick={() => navigate('next')}>
                    <KeyboardArrowRightIcon />
                </div>
            </div>

            {/* Straight line for the bottom section */}
            <div className="straight-line"></div>
            {/* Bottom section for navigation */}
            <div className="bottom-section-navbar" ref={timelineRef}>
                {
                    timelineData.map((item, index) => (
                        <div 
                            key={index}
                            onClick={() => jumpToYear(index)}
                            className="timeline-item-container"
                        >
                            <div className={`timeline-item ${index === currentIndex ? 'active' : ''}`}></div>
                            <div className={`timeline-year ${index === currentIndex ? 'active' : ''}`}>{item.year}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}