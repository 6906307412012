import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { RegisterForm } from '../sections/auth/register';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const { t } = useTranslation();
  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("currentLanguage"));
  } , []);

  return (
    <>
      <Helmet>
        <title> {t('CreateYourAccount')}</title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

 {/* 2024-05-17: Now we just don't want it anymore, so we add false here */}
        {mdUp && false && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 12, mt: 0, mb: 10  }}>
              {t('HiWelcomeBack')}
            </Typography>
            <img src="/assets/images/covers/cover1009.png" alt="login" /><br/>
            <img src="/assets/images/covers/cover10011.png" alt="login" /><br/>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('CreateYourAccount')}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {t('AlreadyHaveAnAccount')}
              <Link variant="subtitle2" style={{ cursor: 'pointer' }} href="loginfDFewfDfeDFSFFue738JDuewifDueufsd89rjudef7UEr3457esfd">{t('ClickToLogin')}</Link>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:phone-outline" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:email-outline" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:message-square-outline" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('OR')}
              </Typography>
            </Divider>

            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
