import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import './index.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 60;



const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }), // Enable this if you want to blur the background 
  backgroundColor: 'transparent', // No background blur effects
  boxShadow: 'none',
  position: 'absolute', // Delete this to make the header bar always stay on top of the screen
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`, we don't need this one, since we now want the header to be full width
  },
  // borderBottom: "solid #919eab 2px"
}));


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

const headerNames = [
  {name: "Home", url: "/home"},
  {name: "Blogs", url: "/dashboard/blog"},
  {name: "More", url: "/more"}
];

const headerColors = [
  {name: "Home", dorminantColor: "#e7bca3", hoverColor: "#d6ab92"},
  {name: "Blogs", dorminantColor: "#f8dab9", hoverColor: "#f0caa1"},
  {name: "More", dorminantColor: "#ffe5bc", hoverColor: "#ffdeab"},
];

export default function Header({ onOpenNav, hideMenuIcon, active, handleFullRefreshBlogPage }) {


  // const isMobile = window.innerWidth <= 650;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(active);

  const handleNavigation = (url, index) => {
    // delete the scrolling info of the Blog page to avoid jumping to previous location when navigating through different pages
    localStorage.removeItem("scrollPosition");
    // localStorage.setItem("noNeedToRefetch", "false");// Set the refreshnes flag
    
    navigate(url);
    setActiveIndex(index);
    handleFullRefreshBlogPage();
  };

  const [activePageName, setActivePageName] = useState(headerNames[active].name);
  const [openNavMenu, setOpenNavMenu] = useState(false);

  const handleClickAwayFromMenu = () => {
    // setOpenNavMenu(false);
    if(openNavMenu){
      setOpenNavMenu(false);
    }
  };

  return (
    <StyledRoot>
      <StyledToolbar>


        <Stack
          direction="row"
          sx={{
            // border:"solid red 3px", 
            width: "100%",
            position: "relative",
            display:"flex",
            alignItems:"center",
            // position:"absolute", 
            // left: "50%",
            // transform: "translateX(-50%)", // Correct the alignment by translating it back by 40% of its width
            WebkitTapHighlightColor: "transparent"
          }}
        >
          <div 
            className="headerAuthorName"
          >
            Uniqueman Dev
          </div>
          
          <div 
            style={{
              position:"absolute", 
              right:"0px", 
              top: "0px",
              display: isMobile ? "block" : "none", 
              fontSize: "16px",
              // border:"solid red 1px",
              color: "#281a2c",
              cursor: "pointer",

            }}
            onClick={() => setOpenNavMenu(prev => !prev)}
          >
            
            <div style={{
              border: "solid #8b97a2 1px",
              borderRadius: "3px",
              padding: "6px 10px",
              display:"flex",
              alignItems:"center",
              justifyContent:"center"
            }}>
              {activePageName}

              {
                openNavMenu
                ? <div style={{
                    marginLeft:"7px",
                    width: '0',
                    height: '0',
                    borderLeft: '5px solid transparent',
                    borderRight: '5px solid transparent',
                    borderBottom: '6px solid #637381',
                  }}></div>
                : <div style={{
                  marginLeft:"7px",
                  width: '0',
                  height: '0',
                  borderLeft: '5px solid transparent',
                  borderRight: '5px solid transparent',
                  borderTop: '6px solid #637381',
  
                }}></div>
              }



              
            </div>

            {openNavMenu && (
              <ClickAwayListener onClickAway={handleClickAwayFromMenu}>
                {/* New div for additional options */}
                <div style={{
                  marginTop: "3px", // Space between the two divs
                  border: "solid #8b97a2 1px",
                  borderRadius: "3px", // Rounded corners for the new div
                }}>
                  {
                    headerNames.map((item, index) => (
                      <>
                        <div style={{
                          border: index === 0 ? '' : "solid 1px #637381",
                          borderRadius: "5px",
                          // marginBottom: "3px",
                          width: "90%",
                          margin:"3px auto"
                        }}></div>
                        
                        <div 
                          style={{
                            margin:"3px", 
                            display:"flex", 
                            alignItems:"center", 
                            justifyContent:"center",
                            borderRadius: "5px"
                            // borderTop: index === 0 ? '' : 'solid 1px #637381'
                          }}
                          key={index}
                          onMouseOver={(e) => e.currentTarget.style.backgroundColor = headerColors[active].hoverColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                          onClick={() => handleNavigation(item.url, index)}
                        >
                          {item.name}
                        </div>
                      </>
                    ))
                  }
                </div>
              </ClickAwayListener>
            )}
          </div>

          <div style={{position:"absolute", right: "0px", display: isMobile ? "none" : "flex"}}>
            {
              headerNames.map((item, index) => (
                <div 
                  className={`headerSectionName ${activeIndex === index ? 'active' : '' }`}
                  key={index}
                  onClick={() => handleNavigation(item.url, index)}
                  // style={{fontWeight: index == 0 ? 'bolder' : '', fontSize: index == 0 ? '25px' : ''}}
                  onMouseOver={(e) => {
                    if(index !== active) {
                      e.currentTarget.style.backgroundColor = headerColors[active].hoverColor
                    }
                  }}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                >
                  {item.name}
                  {activeIndex === index && <div className="underline"></div>}
                </div>
              ))
            }
          </div>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >

          {/* 2024-02-06: We finally decided to cancel the multiple languages support */}

          {
            localStorage.getItem("userLoggingInfo") && <AccountPopover />
          }
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
