import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = async () => {
    // navigate('/dashboard', { replace: true });
    const payload = {
      "email" : email,
      "password" : password
    }

    const requestOptions = {
      method : "POST",
      headers : { 'Content-Type' : 'application/json' },
      body : JSON.stringify(payload)
    }

    fetch(`${process.env.REACT_APP_API_URL}/admin/authentication/login?language=en`, requestOptions)
      .then(response => {
        if(!response.ok) {
          return response.text();
        } 
          return response.json();
      })
      .then(data => {
        if (typeof data === 'string') {
          window.alert(data);
        } else {
          // Save login status to localStorage
          const loginInfo = {
            isLoggedIn: true,
            expirationDate: Math.floor(Date.now() / 1000) + 28800
          };
          localStorage.setItem('userLoggingInfo', JSON.stringify(loginInfo));
          navigate('/dashboard', { replace: true });
        }
      })
      .catch(error => {
        window.alert(error);
      });

  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label={t('EmailLoginForm')} onChange={ (e) => setEmail(e.target.value) }/>

        <TextField
          name="password"
          label={t('PasswordLoginForm')}
          onChange={ (e) => setPassword(e.target.value) }
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        {t('login')}
      </LoadingButton>
    </>
  );
}
