import {useState, useRef,useEffect} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from '@mui/material/Tooltip';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import IconButton from '@mui/material/IconButton';
import './markdownCustomize.css';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {nord} from 'react-syntax-highlighter/dist/esm/styles/prism';

import PreviewIcon from '@mui/icons-material/Preview';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import UpdateIcon from '@mui/icons-material/Update';
import CategoryIcon from '@mui/icons-material/Category';
import ImageUploaderComponent from './ImageUploaderComponent';
import InsertImageInPostComponent from './InsertImageInPostComponent';


export default function EditPage(props) {
    const isMobile = window.innerWidth <= 650;

    const navigate = useNavigate();
    // When this component is accessed by clicking the Edit button from a certain post article
    // the article's id will be transferred here through the URL
    // so we need to retrieve it from the URL using useParams() method
    //
    // When the user created a new post in the category and the edit page will show up to let the 
    // user finish the work.In order to distinguish the two sources,we introduced isFromNavSection.
    let { postId } = useParams();
    // Since useParam() must be called in the exact same order in every component render,we can't use the
    // conditional logic here,so we first assign its value to postId,then if it comes from the <NavSection/>
    // we change its value,otherwise, keep it there.
    if(props.isFromNavSection) {
      postId = props.postId;
    }

    const [openPreviewOnMobile,setOpenPreviewOnMobile] = useState(false);
    const [openDiscardConfirmDialog, setOpenDiscardConfirmDialog] = useState(false);
    const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
    const [openSyncAllConfirmDialog, setOpenSyncAllConfirmDialog] = useState(false);
    const [openPostConfirmDialog, setOpenPostConfirmDialog] = useState(false);



    useEffect(() => {

      if(props.isFromNavSection) {
        // If it comes from the <NavSection/> ,of course it's a new article
        setIsNewArticle(true);
      } else {
        if(postId !== '1'){
          // If it comes from the Edit button click on the top edge of the post article,it's not a new article
          setIsNewArticle(false);
        }
      }
  

      const userAgent = window.navigator.userAgent;
        let platformDetail;
        let platform;
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
          platformDetail = 'Apple Phone';
          platform = 'mobile';
        } else if (/Macintosh|Mac/i.test(userAgent)) {
          platformDetail = 'Apple Computer';
          platform = 'pc';
        } else if (/Android/i.test(userAgent)) {
          platformDetail = 'Android Phone';
          platform = 'mobile';
        } else if (/Windows/i.test(userAgent)) {
          platformDetail = 'Windows Computer';
          platform = 'pc';
        } else if (/Linux|Ubuntu|Manjaro|Nix|Fedora|Centos|Redhat|kodachi|FreeBSD/i.test(userAgent)) {
          platformDetail = 'Linux Computer';
          platform = 'pc';
        } else {
          platformDetail = 'Android Phone';
          platform = 'mobile';
        }

      // If it comes from the <NavSection /> we don't need to borther the backend server and try to fetch data by postId
      // We directly set the default value based on the information we received
      if(props.isFromNavSection) {
        setTitle(props.postTitle);
        setCoverImageUrl("");
        setCategories(props.categoryId);
        setShortDesc("");
        setMarkdownContent("");
        setOriginalPostId(props.postId);
      } else {
        fetch(`${process.env.REACT_APP_API_URL}/posts/` + postId + "?platform=" + platform + "&platformDetail=" + platformDetail)
        .then(response => response.json())
        .then(data => {
          // window.alert(data.title);
          setTitle(data[0].title);
          setCoverImageUrl(data[0].coverImagePath);
          setCategories(data[0].categories);
          setShortDesc(data[0].shortDescription);
          setMarkdownContent(data[0].content);
          setOriginalPostId(data[0].id);
        })
        .catch(error => console.log(error));
      }
      
       
        setCategoryTree(JSON.parse(localStorage.getItem("categoriesTree")));
       
        // We also want to prevent the user from zooming in or out the page
        // Disable user scaling
        const disableUserScaling = () => {
          const viewportMetaTag = document.querySelector('meta[name="viewport"]');
          viewportMetaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
        };
  
        disableUserScaling();
    } , []);

    const [categoryTree,setCategoryTree] = useState({});
    const [markdownContent, setMarkdownContent] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const sourceRef = useRef(null);
    const targetRef = useRef(null);

    const [originalPostId,setOriginalPostId] = useState('');
    const [relatedCode, setRelatedCode] = useState('');
    const [coverImageUrl,setCoverImageUrl] = useState('');

    const handleImageUploadFinished = (imageurl) => {
      setCoverImageUrl(imageurl);
    };

    const [title,setTitle] = useState('');
    const [categories,setCategories] = useState('');
    const [shortDesc,setShortDesc] = useState('');
    const [openHeaderEditingDialog, setOpenHeaderEditingDialog] = useState(true);
    const [isNewArticle,setIsNewArticle] = useState(true);
    // When posting new articles,the backend server will translate it into many languages,which may take a few seconds,
    // so we open up a backdrop to display some information during the waiting time
    const [openTranslatingPleaseWait,setOpenTranslatingPleaseWait] = useState(false);
    const [syncingPleaseWait,setSyncingPleaseWait] = useState(false);

    const handleMarkdownContent  = (event) => {
        setMarkdownContent(event.target.value);
      }
    
    const handleImageInsertFinished = (imageurl) => {
      const imageUrl = imageurl;
  
      // get the current cursor position in textarea
      const textarea = document.getElementById("markdownTextarea");
      const {selectionStart, selectionEnd} = textarea;

      // construct the markdown syntax for the image
      const markdownSyntax = `![image](${imageUrl})`;

      // insert the markdown syntax at the cursor position
      const currentContent = textarea.value;
      const newContent = currentContent.substring(0,selectionStart) + "\n" + markdownSyntax + "\n" + currentContent.substring(selectionEnd);

      // update the textarea value with the new content
      textarea.value = newContent;
      textarea.selectionStart = selectionStart + markdownSyntax.length + 2;
      textarea.selectionEnd = selectionStart + markdownSyntax.length + 2;

      // Focus the textarea
      textarea.focus();
  
    };
      const toggleShowPreview = () => {
        setShowPreview(!showPreview);
        setOpenPreviewOnMobile(!openPreviewOnMobile);
      }

      const handleSourceScroll = () => {
        if (sourceRef.current && targetRef.current) {
          const sourceElement = sourceRef.current;
          const targetElement = targetRef.current;
    
          const sourceScrollHeight = sourceElement.scrollHeight - sourceElement.clientHeight;
          const targetScrollHeight = targetElement.scrollHeight - targetElement.clientHeight;
    
          const scrollPercentage = sourceElement.scrollTop / sourceScrollHeight;
          const targetScrollTop = scrollPercentage * targetScrollHeight;
    
          targetElement.scrollTop = targetScrollTop;
          targetElement.scrollLeft = sourceElement.scrollLeft;
        }
      };
    
      const handleTargetScroll = () => {
        if (sourceRef.current && targetRef.current) {
          const sourceElement = sourceRef.current;
          const targetElement = targetRef.current;
    
          const sourceScrollHeight = sourceElement.scrollHeight - sourceElement.clientHeight;
          const targetScrollHeight = targetElement.scrollHeight - targetElement.clientHeight;
    
          const scrollPercentage = targetElement.scrollTop / targetScrollHeight;
          const sourceScrollTop = scrollPercentage * sourceScrollHeight;
    
          sourceElement.scrollTop = sourceScrollTop;
          sourceElement.scrollLeft = targetElement.scrollLeft;
        }
      };


      const continueToNext = () =>  {
        setOpenHeaderEditingDialog(false);
      }

      const handleCloseHeaderEditingDialog = () => {
        setOpenHeaderEditingDialog(false);
      }

      const handlePostArticle = () => {
        setOpenPostConfirmDialog(false);
        // Only open it after post verification in springboot
        // setOpenTranslatingPleaseWait(true);
        let currentLanguage = "en";
        const payload = {
          "id" : props.postId,
          "title" : title,
          "content" : markdownContent,
          "language" : currentLanguage,
          "shortDescription" : shortDesc,
          "coverImagePath" : coverImageUrl,
          "contentCode" : relatedCode,
          "categories" : categories
          };
        if(!payload.title) {return window.alert("Post title can't be empty.");}
        if(!payload.content) {return window.alert("Post content can't be empty.");}
        if(!payload.shortDescription) {return window.alert("Short description can't be empty.");}
        if(!payload.coverImagePath){return window.alert("Cover image path can't be empty.");}
        if(!payload.categories){return window.alert("Category name can't be empty.");}
        // After the validation,the input data are all valid,let's open up the backdrop and the dialog to 
        //  wait for the backend to translate article
        setOpenTranslatingPleaseWait(true);
        const requestOptions = {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: JSON.stringify(payload)
        };

        fetch(`${process.env.REACT_APP_API_URL}/posts/save/a/post?language=` + currentLanguage,requestOptions)
          .then(response => {
            return  response.text();
          })
          .then(data => {
            // No matter what happends,we should alert the text and close unnecessary dialogs
            window.alert(data);
            setOpenPostConfirmDialog(false);
            
            setOpenTranslatingPleaseWait(false);
            navigate(`/`);
            
          })
          .catch(error => {
            window.alert(error);
            console.log(error);
          });
      }

      const handleSyncAllTranslations = () => {
        setSyncingPleaseWait(true);
        // window.alert("All translations have been synced!");
        let currentLanguage = "en";
        const payload = {
          "title" : title,
          "content" : markdownContent,
          "shortDescription" : shortDesc,
          "coverImagePath" : coverImageUrl,
          "contentCode" : relatedCode,
          "categories" : categories
        };       
        const requestOptions = {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: JSON.stringify(payload)
        };

        fetch(`${process.env.REACT_APP_API_URL}/posts/update/a/post/sync/all/` + originalPostId + "?language=" + currentLanguage,requestOptions)
          .then(response => response.text())
          .then(data => {
            setSyncingPleaseWait(false);
            window.alert(data);
            navigate(`/`);
          })
          .catch(error => {
            window.alert(error);
            console.log(error);
          }); 
      }

      const handleUpdateArticle = () => {
        let currentLanguage = "en";
        const payload = {
          "title" : title,
          "content" : markdownContent,
          "shortDescription" : shortDesc,
          "coverImagePath" : coverImageUrl,
          "contentCode" : relatedCode,
          "categories" : categories
        };       
        const requestOptions = {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: JSON.stringify(payload)
        };

        fetch(`${process.env.REACT_APP_API_URL}/posts/update/a/post/` + originalPostId + "?language=" + currentLanguage,requestOptions)
          .then(response => response.text())
          .then(data => {
            window.alert(data);
            navigate(`/`);
          })
          .catch(error => {
            window.alert(error);
            console.log(error);
          }); 
      }

    return(
      <>
          <Dialog open={openHeaderEditingDialog} onClose={handleCloseHeaderEditingDialog}>
          <DialogTitle  style={isMobile? {fontSize:"16px"} : {}}>Post Headers</DialogTitle>
          <DialogContent style={isMobile? {fontSize:"1px"} : {}}>
            <DialogContentText  style={isMobile? {fontSize:"14px"} : {}}>
            To add a new post , pleast first provide the following information.
            </DialogContentText>
            
            <div >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Post Title"
                type="text"
                fullWidth
                variant="standard"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                size={isMobile ? "small" : "normal"}
              /> 
            </div>
            <div style={{height: isMobile ? "20px" : "30px"}}></div>

           
            <div style={{display:"flex",fontSize: isMobile ? "3px" : "12px"}}>
                  <TextField
                      id="coverImage"
                      label="Cover Image"
                      value={coverImageUrl}
                      multiline
                      fullWidth
                      maxRows={1}
                      style={{margin:"10px auto"}}
                      size={isMobile ? "small" : "normal"}
                  />
                  <ImageUploaderComponent onImageUploadFinished={handleImageUploadFinished}/>
                  
                </div>
                <div style={{height: isMobile ? "0px" : "20px"}}></div>
           
            <div style={{height: isMobile ? "20px" : "20px"}}></div>
            
            {/* <CategoryDropdown categories={categoryTree.children}/> */}
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description(60 words)"
              value={shortDesc}
              multiline
              fullWidth
              maxRows={isMobile ? 4 : 6}
              onChange={(e) => setShortDesc(e.target.value)}
              size={isMobile ? "small" : "normal"}
            /><br/>



          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseNewPostDialog}>Cancel</Button> */}
            <Button onClick={continueToNext}>Confirm</Button>
          </DialogActions>
        </Dialog>

       
        {
          openTranslatingPleaseWait
          ? <div>
              <Dialog
                open={openTranslatingPleaseWait}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{marginBottom:"40vh"}}
              >
                <DialogTitle id="alert-dialog-title">
                Loading, Please wait...
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  We've received your excellent article,now our hardworking backend server is running at full capacity to translate it into multiple languages,to help spread and grow your business globally.Now close your eyes,take a deep breath,it'll be ready soon.
                  </DialogContentText>
                </DialogContent>
              
              </Dialog>
             
                <Backdrop
                  sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={openTranslatingPleaseWait}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
            </div>
          : <></>
        }

        {
          syncingPleaseWait
          ? <div>
              <Dialog
                open={syncingPleaseWait}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{marginBottom:"30vh"}}
              >
                <DialogTitle id="alert-dialog-title">
                Synchronizing...
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Great things take time. Your post is undergoing translation magic!
                  </DialogContentText>
                </DialogContent>
              
              </Dialog>
             
                <Backdrop
                  sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={syncingPleaseWait}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
            </div>
          : <></>
        }
        

        <div style={{width:"100%",display:"flex",justifyContent: "center",margin:"10px auto"}}>
            <div style={{borderRight: isMobile ? "" : "solid #84919d 5px",width: isMobile ? "100%" : "46%"}}>
                <div style={isMobile ? {margin:"0px",display:"flex"} : {margin:"3px",display:"flex"}}>
                    
                    {/* If it's from <NavSection />,for mobile devices,we need to provide a button to toggle open the drawer */}
                    {
                      props.isFromNavSection && isMobile
                        ? <Tooltip title="Open category" style={{marginRight:"5px"}}  onClick={props.onOpenNav}>
                            <IconButton>
                              <CategoryIcon />
                            </IconButton>
                          </Tooltip>
                        : <></>
                    }

                    <Tooltip title="Edit Header" style={{marginRight:"5px"}}  onClick={ () => setOpenHeaderEditingDialog(true) }>
                      <IconButton>
                        <EditCalendarIcon />
                      </IconButton>
                    </Tooltip>
                    <div style={{border:"solid #747474 1px",height: "20px",borderRadius:"40px",position:"relative",top:"10px"}}></div>
                    
                    <InsertImageInPostComponent onImageInsertFinished={handleImageInsertFinished}/>


                    <div style={{border:"solid #747474 1px",height: "20px",borderRadius:"40px",position:"relative",top:"10px"}}></div>
                    <Tooltip title="Preview" style={{margin:"0px 5px"}} onClick={toggleShowPreview}>
                      <IconButton>
                        <PreviewIcon />
                      </IconButton>
                    </Tooltip>
                    <div style={{border:"solid #747474 1px",height: "20px",borderRadius:"40px",position:"relative",top:"10px"}}></div>
                   
                    <Tooltip
                      title="Discard"
                      style={{margin:"0px 5px"}}
                      onClick={() => {setOpenDiscardConfirmDialog(true);}}
                    >
                      <IconButton>
                        <DeleteSweepIcon />
                      </IconButton>
                    </Tooltip>
                    <Dialog
                      open={openDiscardConfirmDialog}
                      onClose={() => {setOpenDiscardConfirmDialog(false);}}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      Discard changes
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Are you sure to discard changes and return to the main page?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => {setOpenDiscardConfirmDialog(false);}} autoFocus>
                          Cancel
                        </Button>
                        <Button onClick={() => {navigate(`/`);}}>
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={openUpdateConfirmDialog}
                      onClose={() => {setOpenUpdateConfirmDialog(false);}}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      CommitChanges
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Great changes takes time,now you can click the confirm button to commit your changes.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        
                        <Button onClick={() => {setOpenUpdateConfirmDialog(false);}} autoFocus>
                        Hang on a second
                        </Button>
                        <Button onClick={handleUpdateArticle}>Confirm</Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={openSyncAllConfirmDialog}
                      onClose={() => {setOpenSyncAllConfirmDialog(false);}}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      Sync All
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Great things take time, and now, with our synchronization feature, you can effortlessly apply changes to all three languages simultaneously, ensuring a seamless and efficient translation process!
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        
                        <Button onClick={() => {setOpenSyncAllConfirmDialog(false);}} autoFocus>
                          Cancel
                        </Button>
                        <Button onClick={() => {
                          setOpenSyncAllConfirmDialog(false);
                          handleSyncAllTranslations();
                        }}>
                          Sync All
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={openPostConfirmDialog}
                      onClose={() => {setOpenPostConfirmDialog(false);}}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      Post Article
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Congratulations on finishing your work! Take a deep breath and let's do a quick review together. Click Confirm to share your masterpiece with the world, or Cancel to make any last-minute changes.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        
                        <Button onClick={() => {setOpenPostConfirmDialog(false);}} autoFocus>
                          Cancel
                        </Button>
                        <Button onClick={handlePostArticle}>Confirm</Button>
                      </DialogActions>
                    </Dialog>
                    <div style={{border:"solid #747474 1px",height: "20px",borderRadius:"40px",position:"relative",top:"10px"}}></div>
                    {
                      isNewArticle
                      
                      ? <Tooltip title="Post" style={{margin:"0px 5px"}} onClick={() => {setOpenPostConfirmDialog(true);}}>
                          <IconButton>
                            <CloudUploadIcon />
                          </IconButton>
                        </Tooltip>
                      :  (
                            <>
                              
                              <Tooltip title="Update" style={{margin:"0px 5px"}} onClick={() => {setOpenUpdateConfirmDialog(true);}}>
                                <IconButton>
                                  {/* <UpdateIcon /> */}
                                  <CloudSyncIcon />
                                </IconButton>
                              </Tooltip>
                              <div style={{border:"solid #747474 1px",height: "20px",borderRadius:"40px",position:"relative",top:"10px"}}></div>
                              
                              {/* <Tooltip title="Sync All" style={{margin:"0px 5px"}} onClick={() => {setOpenSyncAllConfirmDialog(true);}}>
                                <IconButton>
                                  <CloudSyncIcon />
                                </IconButton>
                              </Tooltip> */}
                            </>
                          ) 
                    }
                </div>
                {
                  openPreviewOnMobile && isMobile
                  ? <div 
                       className="tabbed-textarea"
                       style={ isMobile ? {fontSize:"14px",overflow:"auto",wordWrap:"break-word", marginLeft:"5px",marginRight:"5px",width:"98%",height:"98vh"} : {overflow:"auto",wordWrap:"break-word", marginLeft:"10px",width:"46%",height:"98vh"}}
                       ref={targetRef}
                       onScroll={handleTargetScroll}
                    >
                      {/* <ReactMarkdown>{markdownContent}</ReactMarkdown> */}
                      <ReactMarkdown
                          children={markdownContent}
                          remarkPlugins={[remarkMath]}
                          rehypePlugins={[rehypeKatex]}
                          allowDangerousHtml={true}  // Enable HTML rendering
                          components={{
                              img: ImageRenderer,
                              a: LinkRenderer,
                              code : CodeRenderer
                          }}
                      />
                    </div>
                  : <div style={{margin:"10px"}}>
                        <textarea 
                            className="tabbed-textarea"
                            id="markdownTextarea"
                            value={markdownContent}
                            onChange={handleMarkdownContent}
                            placeholder="Type your markdown content here ..."
                            style={{
                                border : "none",
                                outline : "none",
                                width:"100%",
                                height:"93vh",
                                resize:"none",
                                scrollbarWidth: 'thin',
                                scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)'
                            }}
                            ref={sourceRef}
                            onScroll={handleSourceScroll}
                            rows={10}
                        />
                    </div>
                }
            </div>
            {
                showPreview && !isMobile
                ? <div 
                     className="tabbed-textarea"
                     style={{overflow:"auto",wordWrap:"break-word", marginLeft:"10px",width:"46%",height:"98vh"}}
                     ref={targetRef}
                     onScroll={handleTargetScroll}
                  >
                    {/* <ReactMarkdown>{markdownContent}</ReactMarkdown> */}
                    <ReactMarkdown
                        children={markdownContent}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeKatex]}
                        allowDangerousHtml={true}  // Enable HTML rendering
                        components={{
                            img: ImageRenderer,
                            a: LinkRenderer,
                            code : CodeRenderer
                        }}
                    />
                  </div>
                : <></>
            }
        </div>
    </>
    );
}

// customized rendering for images
const ImageRenderer = ({ src, alt }) => (
  <img src={src} alt={alt} style={{ width: '100%', height: 'auto' }} />
);

// Customized rendering for hyper links
const LinkRenderer = ({ children, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      style={{
        color: '#3eb0ef',
        textDecoration: 'none', // Remove underline if desired
      }}
      onMouseEnter={(e) => {
        e.target.style.color = '#1e7ca8'; // Darker color on hover
        e.target.style.textDecoration = 'underline';
      }}
      onMouseLeave={(e) => {
        e.target.style.color = '#3eb0ef'; // Restore original color when not hovering
        e.target.style.textDecoration = 'none';
      }}
    >
      {children}
    </a>
  );
};

// CodeRenderer component
const CodeRenderer = ({ node, inline, className, children, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);
  const match = /language-(\w+)/.exec(className || '');
  const isMobile = window.innerWidth <= 650;

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

 
  if (inline) {
      return (
        <div style={isMobile ? {fontSize:"12px",display:"inline-block",marginLeft:"5px",marginRight:"5px",position:"relative",top:"0px",borderRadius:"5px"} : {fontSize:"16px",display:"inline-block",marginLeft:"5px",marginRight:"5px",position:"relative",top:"-3px",borderRadius:"5px"}}>
          <div style={isMobile ? { overflow: "hidden", height: "23px",borderRadius:"5px",display: "flex", alignItems: "center" } : { overflow: "hidden", height: "30px",borderRadius:"5px",display: "flex", alignItems: "center"}}>
              <SyntaxHighlighter
                  {...props}
                  style={{ ...nord}}
                  language="vim"
                  PreTag="span"
                  >
                  {children}
              </SyntaxHighlighter>
          </div>
        </div>
      );
    }

  return !inline && match ? (
      // If we use calssName to set the style , it won't work,probably it's been overridden by some other settings
      // Since inline style has higher specificity than class-based styles
    <div style={isMobile ? {fontSize:"12px",lineHeight:"16px",position: 'relative'} : {position: 'relative'}}>
      <SyntaxHighlighter
        {...props}
        children={String(children).replace(/\n$/, '')}
        style={nord}
        language={match[1]}
        PreTag="div"
      />
      <Button 
          variant="contained" 
          component="label"  
          onClick={handleCopy} 
          style={ isMobile ? {fontSize:"9px",position: 'absolute', top: 6, right: 6 } : {position: 'absolute', top: 9, right: 9 }}
          >
        {isCopied ? 'Copied!' : 'Copy'}
      </Button>
    </div>
  ) : (
    <code {...props} className={className}>
      {children}
    </code>
      
  );
};
