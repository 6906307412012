import './OpenSourceProjects.css';
import { Grid, Card } from '@mui/material';

const projects = [
    {
        "name" : "fullstack-react-springboot-docker",
        "description" : "A modern, scalable web application that leverages React for a dynamic frontend, Spring Boot for a robust backend, and Spring Security for comprehensive security measures.",
        "githublink" : "https://github.com/rogershi-dev/fullstack-react-springboot-docker",
        "docslink" : "https://uniqueman.dev/showPostDetails/1731363525929",
        "languages" : [
            {
                "language" : "Java",
                "color" : "#995449"
            },
            {
                "language" : "JavaScript",
                "color" : "#aba71f"
            }
        ],
    },

    {
        "name" : "LeetCode-Java",
        "description" : "This repository provides Java solutions to common LeetCode coding questions",
        "githublink" : "https://github.com/rogershi-dev/LeetCode-Java",
        "docslink" : "",
        "languages" : [
            {
                "language" : "Java",
                "color" : "#995449"
            },
        ],
    },

    {
        "name" : "linkedin-autopost",
        "description" : "An app that posts a linkedin post automatically when you make a commit to GitHub, using ChatGPT to elaborate on the commit.",
        "githublink" : "https://github.com/Code-For-Humans-School/linkedin-autopost",
        "docslink" : "",
        "languages" : [
            {
                "language" : "JavaScript",
                "color" : "#aba71f"
            }
        ],
    },

];

export default function OpenSourceProjects() {


    return(
        <div className="opensource-project-container">
            <div className="opensource-project-title">
                Open Source Projects
            </div>

            <Grid container spacing={3}>
                {
                    projects.map((project, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                            <Card className="opensource-project-item-card">
                                <div className="opensource-project-card-title">{project.name}</div>
                                <div className="opensource-project-card-desc">{project.description}</div>
                                <div className="opensource-project-card-githublink"> 
                                    <a href={project.githublink} target="_blank" >GitHub Repo</a>
                                    {
                                        project.docslink !== "" &&
                                        <a href={project.docslink} target="_blank">Docs Link</a>
                                    }
                                </div>
                                <div className="opensource-project-card-language-container">
                                    {
                                        project.languages.map((languageitem, index) => (
                                            <div className="opensource-project-card-language" key={index}>
                                                <div 
                                                    className="opensource-project-card-language-colorbar"
                                                    style={{backgroundColor: languageitem.color}}
                                                ></div>
                                                <span>{languageitem.language}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
}