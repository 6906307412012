import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
// ----------------------------------------------------------------------

const NAV_WIDTH = 400;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
  backgroundColor: '#f0caa1'
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, onClickCategory,onClickPostTitleInCategoryTree, openEditingPageOnSavingNewPost, handleRefetchPostsAfterDelete }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // In the <NavSection> when user clicks one of the categories, show posts in it
  const handleCategoryClick = (id, clickedCategoryName) => {
    // window.alert(id);
    onClickCategory(id, clickedCategoryName);
  };

  const handlePostTitleClick = (id) => {
    onClickPostTitleInCategoryTree(id);
  };

  // And when the user added a new post in root category, show editing page
  const handleAddedNewPostInRoot = (postTitle, postId, postDate, categoryId) => {
    openEditingPageOnSavingNewPost(postTitle, postId, postDate, categoryId);
  }

  const isDesktop = useResponsive('up', 'lg');
  // set the initial width to NAV_WIDTH
  const [navWidth, setNavWidth] = useState(NAV_WIDTH);
  const [isResizing, setIsResizing] = useState(false);


  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [variableNavWidth,setVariableNavWidth] = useState(400);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    const { clientX, clientY } = event;
    const { left, top } = event.target.getBoundingClientRect();
    setDragOffset({ x: clientX - left, y: clientY - top });
  };

  




  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    const handleMouseUp = () => {
      setIsDragging(false);
      // Once released,return back
      setPosition({
        x: 0,
        y: 0
      });
    };

    const handleMouseMove = (event) => {
      if (isDragging) {
        const { clientX, clientY } = event;
        // setPosition({
        //   x: (clientX - dragOffset.x - variableNavWidth) / 30,
        //   // y: clientY - dragOffset.y,
        //   y: 0 // I only want to drag it horizontally
        // });
        setVariableNavWidth(clientX - dragOffset.x);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDragging, dragOffset]);

  const renderContent = (
    
      <Scrollbar
        sx={{
          height: 1,
          width: '113%',// original scroll bar is ugly,I only want to show the front part of it.
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
        style={{
          // backgroundColor:"#f4f8fb"
        // 2024-11-12:
        // On desktop, the left menu bar is set to always show up, so we need to change the background to '' 
        // so that it won't have the same linear gradient effect as on mobile devices
        background: isDesktop? '' : 'linear-gradient(to bottom, #f8dab9, #f9e8cf, #f5eee0, #ffffff)', // Gradient background
        // padding: '5px 20px',
        }}
      >
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          {/* <Logo /> */}
        </Box>

        <Box sx={{ 
          mb: 5, 
          mx: 4,
          ml: 1.5,
          // width:isDesktop ? (variableNavWidth > 300 ? 300 : variableNavWidth < 263 ? 263 : variableNavWidth) : 270 
          width: 220
          }}
          
        >
          <Link underline="none">
            <StyledAccount>
              {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
              {/* <Avatar src="/assets/images/covers/cover10011.png" alt="photoURL" /> */}


              <Box sx={{ ml: 2 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ color: 'text.primary' }}
                  style={{fontWeight:"bold",fontSize:"19px"}}>
                  {/* {account.displayName} */}
                  {/* {t('UniqueManTech')} */}
                  Blog Categories
                </Typography>

                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {account.role}
                </Typography> */}
              </Box>
            </StyledAccount>
          </Link>
        </Box >

        {/* The following <NavSection /> displays a list of options(Dashboard,user,404,login,blog) 
        from the array `navConfig` in folder './config' */}
        {/* <NavSection data={navConfig} /> */}

        {/* Now I want to change it to a tree view of my website's categories. */}
        
        <NavSection
          onCloseNav={onCloseNav}
          onClickPostTitle={handlePostTitleClick}
          onClickCategory={handleCategoryClick} 
          variableNavWidth={variableNavWidth}
          handleAddedNewPostInRoot={handleAddedNewPostInRoot}
          handleRefetchPostsAfterDelete={handleRefetchPostsAfterDelete}
        />
        
        <Box sx={{ flexGrow: 1 }} />

        {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
            <Box
              component="img"
              src="/assets/illustrations/illustration_avatar.png"
              sx={{ width: 100, position: 'absolute', top: -50 }}
            />

            <Box sx={{ textAlign: 'center' }}>
              <Typography gutterBottom variant="h6">
                Get more?
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                From only $69
              </Typography>
            </Box>

            <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
              Upgrade to Pro
            </Button>
          </Stack>
        </Box> */}
      </Scrollbar>
    
  );

  return (
    // We've cancelled the permanent display of the blog categories on large screens and moved the categories into the drawer
    // But the <Nav /> component still takes the original space on the left side even if it's empty
    // So I directly changed the "display" property to "none" to avoid the unnecessary space occupation
    <div>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          // width: { lg: variableNavWidth < 300 ? 300 : variableNavWidth > 600 ? 600 : variableNavWidth },//change it will affect article cards on the right side, 2024-11-12: This is the only width that can affect the articles on the right
          // border: "solid red 13px"
        }}
      >
        {/* isDesktop &&
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            variant="permanent" // toggle this one to make it always show up
            PaperProps={{
              sx: {
                width: variableNavWidth < 300 ? 298 : variableNavWidth > 598 ? 598 : variableNavWidth,// change it will limit the width of the category
                bgcolor: '',
                // borderRight: '8px solid #e8edf1', // This is the border at the right side of the drawer comoponent
                // marginTop: '64px',
                // borderRadius: '10px'
              },
            }}
          >
            {renderContent}
          </Drawer> */}
        {isDesktop ? (
            <div
              style={{
                // border: "solid red 3px",
                width: "400px",
                paddingTop: "80px",
                // backgroundColor: "transparent",
              }}
            >
              {renderContent}
            </div>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { 
                width: navWidth,
                width: variableNavWidth < 400 ? 398 : variableNavWidth > 598 ? 598 : variableNavWidth,
               },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>

      {
        isDesktop && false
        ?  <div
            style={{
              position: 'fixed',
              top: '46vh',
              left: variableNavWidth < 300 ? 300 : variableNavWidth > 600 ? 600 : variableNavWidth,
              width: '40px',
              height: '40px',
              border: "solid green 2px",
              borderRadius: "40px",
              background: '#c2e0ff',
              cursor: isDragging ? 'grabbing' : 'grab',
              marginLeft: 0,
            }}
            onMouseDown={handleMouseDown}
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
            
          >
            {/* <IconButton>
              <ArrowForwardIosIcon style={{color:"#1159a2"}}/> #919eab
            </IconButton> */}
            <Fab variant="circular" color="#747474" size="small" border="solid #919eab 4px">
              <NavigationIcon sx={{ mr: 0, transform: 'rotate(90deg)',color:"#919eab" }} />
            
            </Fab>
               
          </div>
        : <></>
      }
      </div>

  );

}

// const DraggableComponent = () => {
//   const [isDragging, setIsDragging] = useState(false);
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

//   const handleMouseDown = (event) => {
//     setIsDragging(true);
//     const { clientX, clientY } = event;
//     const { left, top } = event.target.getBoundingClientRect();
//     setDragOffset({ x: clientX - left, y: clientY - top });
//   };

//   const handleMouseMove = (event) => {
//     if (isDragging) {
//       const { clientX, clientY } = event;
//       setPosition({
//         x: clientX - dragOffset.x - 500,
//         y: clientY - dragOffset.y,
//       });
//       setVariableNavWidth(dragOffset.x + variableNavWidth);
//     }
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//     // Once released,return back
//     setPosition({
//       x: 0,
//       y: 0
//     });
//   };

//   return (
//     <div
//       style={{
//         position: 'relative',
//         top: position.y,
//         left: position.x,
//         width: '600px',
//         height: '600px',
//         background: 'blue',
//         border:"solid 2px green",
//         cursor: isDragging ? 'grabbing' : 'grab',
//       }}
//       onMouseDown={handleMouseDown}
//       onMouseMove={handleMouseMove}
//       onMouseUp={handleMouseUp}
//     >
      
//     </div>
//   );
// };

